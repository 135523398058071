interface Page {
  path: string;
  name: string;
  content: string;
  tags: Array<string>;
}

const testPages = [
  {
    name: "About",
    path: 'about',
    tags: ['about'],
    content: `This is an app that primarily used to learn new technologies`
  },
  {
    name: "Users",
    path: 'users',
    tags: ['users'],
    content: 'Users'
  },
  {
    name: "React Router",
    path: 'react-router',
    tags: ['react', 'react-router'],
    content: `If you're familiar with the JavaScript ecosystem, React, and React Router, this serves as a quick overview of React Router v6 with lots of code and minimal explanations.
- For a complete introduction to React Router, do the [Tutorial](https://reactrouterdotcom.fly.dev/docs/en/v6/getting-started/tutorial)
- For extensive documentation on every API, see [API Reference](https://reactrouterdotcom.fly.dev/docs/en/v6/api)
- For a deeper understanding of concepts, see [Main Concepts](https://reactrouterdotcom.fly.dev/docs/en/v6/getting-started/concepts)
`
  }
]

export { testPages }
export type { Page }
