const PageNotFound = () => (
  <div className="page-not-found">
    <img src="404.png" alt="404" width='400' />

    <h3>This Page is Lost</h3>

    <p>You bought a little bracelet for the express purpose of not losing your keys. </p>
    <p>You put a hook on your door specifically meant for keeping your keys. </p>
    <p>You briefly attempted to attach your keys to your phone. </p>
    <p>But here they are. In the dirt.</p>
    <p>In the park across the street from that bar you used to like but decided the last time you went that you probably wouldn’t go again. </p>
    <p>You’ll never find them.</p>
  </div>
)

export default PageNotFound
