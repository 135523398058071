import ReactMarkdown from 'react-markdown'
import { Page } from '../pages'

const PageRenderer = ({ page }: { page: Page }) => (
  <article>
    <h2>{page.name}</h2>

    <ReactMarkdown>
      {page.content}
    </ReactMarkdown>
  </article>
)

export default PageRenderer
