import { Link, Route, Routes } from "react-router-dom"

import logo from './logo.svg'
import { testPages, Page } from './pages'
import PageSearch from './components/PageSearch'
import PageRenderer from './components/PageRenderer'
import PageNotFound from './components/PageNotFound'

function App() {
  return (
    <div className="App">
      <aside className='sidebar d-flex align-items-center flex-column'>
        <div className='header-top'>
          <img src={logo} className="App-logo" alt="logo" />

          <h1>Newron</h1>
        </div>

        <nav>
          <ul className='list-group list-group-flush'>
            <li className='list-group-item'><Link to="/">Search</Link></li>

            {testPages.map((page: Page) => (
              <li key={page.path} className='list-group-item'><Link to={page.path}>{page.name}</Link></li>
            ))}
          </ul>
        </nav>
      </aside>

      <main className="page-wrapper">
        <Routes>
          <Route path="/" element={<PageSearch />} />

          {testPages.map((page: Page) => (
            <Route key={page.path} path={page.path} element={<PageRenderer {...{ page }} />} />
          ))}

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
    </div>
  )
}

export default App
